/* eslint-disable no-template-curly-in-string */
import Router, { Resource } from 'tg-resources';
import reverseUrl from 'core/utils/urls';
import { getCookie } from 'core/utils/cookie';

/**
 * @typedef {Object} KRAHRouter
 * @property {Resource} bodyExpansionTypeDetail
 * @property {Resource} bodyExpansionTypeList
 * @property {Resource} cuttingOptions
 * @property {Resource} formulaCalculation
 * @property {Resource} measurementList
 * @property {Resource} openView
 * @property {Resource} operatorsList
 * @property {Resource} overtimesDetail
 * @property {Resource} overtimesList
 * @property {Resource} partActionsList
 * @property {Resource} partProductBulkUpdate
 * @property {Resource} partProductDetail
 * @property {Resource} partProductOverlappingActions
 * @property {Resource} partProductPlan
 * @property {Resource} partTemplates
 * @property {Resource} partTemplateUpdate
 * @property {Resource} partTypesList
 * @property {Resource} partWorkPlans
 * @property {Resource} pipeFieldUpdate
 * @property {Resource} pipeProductDetail
 * @property {Resource} pipeProductEndmashineData
 * @property {Resource} pipeProductFaultyStats
 * @property {Resource} pipeProductPause
 * @property {Resource} pipeCancelPrediction
 * @property {Resource} pipeProductPlanned
 * @property {Resource} pipeProductStart
 * @property {Resource} pipesExport
 * @property {Resource} orderAlwaysInSystem
 * @property {StationsRouter} stations
 * @property {WorkPlanRouter} workPlan
 * @property {PartProductRouter} partProducts
 * @property {TimeTrackerRouter} timeTrackers
 * @property {MachineDataRouter} machineData
 * @property {DowntimeRouter} downtimes
 * @property {bomComponentArticle} bomComponentArticle
 */

/** @type KRAHRouter */
const api = new Router(
    {
        bodyExpansionTypeDetail: new Resource(
            reverseUrl('workplan:body-expansion-type-detail', '${pk}'),
        ),
        bodyExpansionTypeList: new Resource(
            reverseUrl('workplan:body-expansion-type-list'),
        ),
        cuttingOptions: new Resource(reverseUrl('workplan:cutting-options')),
        formulaCalculation: new Resource(
            reverseUrl('workplan:formula-calculation-list'),
        ),
        measurementList: new Resource(reverseUrl('measurements-list')),
        openView: new Resource(reverseUrl('open-view')),
        operatorsList: new Resource(reverseUrl('accounts:operator-list')),
        overtimesDetail: new Resource(
            reverseUrl('accounts:overtime-detail', '${pk}'),
        ),
        overtimesList: new Resource(reverseUrl('accounts:overtime-list')),
        partActionsList: new Resource(reverseUrl('part-actions-list')),
        partProductBulkUpdate: new Resource(
            reverseUrl('partproduct-bulk-update'),
        ),
        partProductDetail: new Resource(
            reverseUrl('partproduct-detail', '${pk}'),
        ),
        partProductOverlappingActions: new Resource(
            reverseUrl('partproduct-overlapping-actions'),
        ),
        partProductPlan: new Resource(reverseUrl('partproduct-plan')),
        partProductRevoke: new Resource(reverseUrl('partproduct-revoke')),
        partProductUnplan: new Resource(reverseUrl('partproduct-unplan')),
        partTemplates: new Resource(reverseUrl('part-template-list')),
        partTemplateUpdate: new Resource(
            reverseUrl('part-template-detail', '${pk}'),
        ),
        partTypesList: new Resource(reverseUrl('part-types-list')),
        partWorkPlans: new Resource(reverseUrl('part-work-plans')),

        pipeFieldUpdate: new Resource(reverseUrl('pipe-field-update', '${pk}')),
        pipeProductDetail: new Resource(
            reverseUrl('pipeproduct-pipe', '${pk}'),
        ),
        pipeProductEnd: new Resource(reverseUrl('pipeproduct-end', '${pk}')),
        pipeProductFaultyStats: new Resource(
            reverseUrl('pipeproduct-faulty-stats', '${pk}'),
        ),
        pipeProductPause: new Resource(
            reverseUrl('pipeproduct-pause', '${pk}'),
        ),
        pipeCancelPrediction: new Resource(
            reverseUrl('pipeproduct-cancel-prediction', '${pk}'),
        ),
        pipeProductPlanned: new Resource(reverseUrl('pipeproduct-planned')),
        pipeProductStart: new Resource(
            reverseUrl('pipeproduct-start', '${pk}'),
        ),
        pipesExport: new Resource(reverseUrl('pipes-export')),
        pipeProduceNew: new Resource(reverseUrl('pipe-produce-new', '${pk}')),

        orderAlwaysInSystem: new Resource(
            reverseUrl('always-in-system', '${pk}'),
        ),

        /**
         * @property {Resource} list
         * @property {Resource} subscriptionsList
         * @property {Resource} subscriptionsStop
         * @property {Resource} subscriptionDetail
         * @property {Resource} subscriptionChangeStations
         * @typedef {Router} StationsRouter
         */

        /** @type StationsRouter */
        stations: new Router({
            list: new Resource(reverseUrl('stations-list')),
            subscriptionsList: new Resource(
                reverseUrl('stations-subscriptions-list'),
            ),
            subscriptionsStop: new Resource(
                reverseUrl('stations-subscriptions-stop', '${pk}'),
            ),
            subscriptionDetail: new Resource(
                reverseUrl('stations-subscriptions-detail', '${pk}'),
            ),
            subscriptionChangeStations: new Resource(
                reverseUrl('stations-subscriptions-change-stations', '${pk}'),
            ),
        }),

        /**
         * @typedef {Router} MachineDataRouter
         * @property {Resource} changeMode
         */

        /** @type MachineDataRouter */
        machineData: new Router({
            changeMode: new Resource(
                // '-list' added unsure why DFR does that in this case. Would be nice to remove
                reverseUrl('machine-operation-mode-switch-list'),
            ),
        }),

        /**
         * @typedef {Router} WorkPlanRouter
         * @property {Resource} partWorkOrders
         * @property {Resource} workPlanRouterActionsBulkUpdate
         */

        /** @type WorkPlanRouter */
        // TODO: move here another workplan stuff
        workPlan: new Router({
            partWorkOrders: new Resource(
                reverseUrl('workplan:part-workorders-list'),
            ),
            workOrderActionsBulkUpdate: new Resource(
                reverseUrl('workplan:part-workorder-actions-bulk-update'),
            ),
        }),

        /**
         * @property {Resource} list
         * @typedef {Router} PartProductRouter
         */

        /** @type PartProductRouter */
        // TODO: move here another part product stuff
        partProducts: new Router({
            list: new Resource(reverseUrl('partproduct-list')),
            componentTagList: new Resource(reverseUrl('component-tags-list')),
            downTimeProblemTagList: new Resource(
                reverseUrl('downtime-problem-tags-list'),
            ),
        }),

        /**
         * @property {Resource} list
         * @typedef {Router} TimeTrackerRouter
         */

        /** @type TimeTrackerRouter */
        timeTrackers: new Router({
            list: new Resource(reverseUrl('time-trackers-list')),
            pause: new Resource(reverseUrl('time-trackers-pause', '${pk}')),
            stop: new Resource(reverseUrl('time-trackers-stop', '${pk}')),
        }),
        /**
         * @property {Resource} list
         * @typedef {Router} DowntimeRouter
         */
        /** @type DowntimeRouter */
        downtimes: new Router({
            list: new Resource(reverseUrl('downtimes-list')),
            solve: new Resource(reverseUrl('downtimes-solve', '${pk}')),
        }),

        /**
         * @property {Resource} list
         * @typedef {Router} BomComponentArticleRouter
         */
        /** @type BomComponentArticleRouter */
        bomComponentArticle: new Router({
            list: new Resource(
                reverseUrl('odoo-bill-of-material-component-articles-list'),
            ),
        }),
    },
    {
        apiRoot: DJ_CONST.SITE_URL,
        headers: () => ({
            Accept: 'application/json',
            'X-CSRFToken': getCookie('csrftoken'),
        }),
    },
);

export default api;
